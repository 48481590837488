import React, { useContext, useEffect, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { FaInstagram, FaFacebook } from 'react-icons/fa'
import { FaSquareXTwitter } from 'react-icons/fa6'
import { IoLogoYoutube } from 'react-icons/io'
import {
  Navbar, 
  NavbarBrand, 
  NavbarContent, 
  NavbarItem, 
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Button,
  Badge
} from "@nextui-org/react";

import { formatNumber } from '../src/formatter'

import useHasMounted from '../hooks/use-has-mounted'
import { UserContext } from '../contexts/user-context'


function Header ({ section, subsection, main }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [showHeader, setShowHeader] = useState(true)
  const hasMounted = useHasMounted()
  
  const userHook = useContext(UserContext)
  const user = userHook.user

  const hasOrg = Boolean(user.organization && user.organization.balance !== null)
  const lowBalance = 8

  useEffect(() => {
    if (typeof window !== 'undefined' && window.isNativeApp) setShowHeader(false)
  }, [])

  if (!hasMounted) return <></>
  if (!showHeader) return <></>

  const linkClass = 'text-primary'

  const SocialMedia = () => {
    return <NavbarMenuItem className='mt-10 flex text-xl'>
      <div className='mr-4'><a href='https://x.com/ChallengeHound'><FaSquareXTwitter /></a></div>
      <div className='mr-4'><a href='https://instagram.com/challengehound'><FaInstagram /></a></div>
      <div className='mr-4'><a href='https://facebook.com/challengehound'><FaFacebook /></a></div>
      <div className='mt-[-2px] text-2xl'><a href='https://www.youtube.com/@challengehound4806'><IoLogoYoutube /></a></div>
    </NavbarMenuItem>
  }

  const CustomNavbarMenuItem = ({ url, name, active, on, desc }) => {
    return <NavbarMenuItem>
      {!active && <><Link
        className={`w-full font-bold ${on ? 'text-primary-700' : ''}`}
        href={url}
        size="lg"
      >
        {name}
      </Link>{desc}</>}
      {active && <span className='text-secondary font-bold'>{name}</span>}
    </NavbarMenuItem>
  }
  
  if (section !== 'public' && user.id) {
    return <Navbar className='pt-0 pb-2 md:py-3 min-h-[75px] md:min-h-[100px] socks' onMenuOpenChange={setIsMenuOpen} classNames={{ item: 'px-1' }}>
      <NavbarBrand>
        <Link href='/'>
          <Image src='/challenge-hound-logo.png' alt='Challenge Hound' width={142} height={50} className='' />
        </Link> 
      </NavbarBrand>
      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarItem isActive={section === 'challenges'}>
          <Link className={linkClass} href='/challenges'>Challenges</Link>
        </NavbarItem>
        <NavbarItem  isActive={section === 'activities'}>
          <Link className={linkClass} href='/activities'>Activities</Link>
        </NavbarItem>
        <NavbarItem isActive={section === 'apps'}>
          <Link className={linkClass}  href='/apps'>Apps</Link>
        </NavbarItem>
        <NavbarItem  isActive={section === 'account'}>
          <Link className={linkClass}  href='/account'>Account</Link>
        </NavbarItem>
        {hasOrg && <NavbarItem isActive={section === 'credits'}>
        <Badge content="low" size='sm' color="danger" className='mr-2 ' isInvisible={user.organization.balance > lowBalance}>
          <Button
            radius='sm'
            color='primary'
            variant='bordered'
            as={Link} 
            href='/credits'
            >{formatNumber(user.organization.balance, 1)} Credits</Button>
        </Badge>

        </NavbarItem>}
      </NavbarContent>
      <NavbarMenuToggle
        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        className="sm:hidden"
      />
      <NavbarMenu className='pt-10 w-full'>
        <CustomNavbarMenuItem url='/challenges' name='Challenges' active={section === 'challenges' && main} on={section === 'challenges'} />
        <CustomNavbarMenuItem url='/activities' name='Activities' active={section === 'activities' && main} on={section === 'activities'} />
        <CustomNavbarMenuItem url='/apps' name='Apps' active={section === 'apps' && main} />
        <CustomNavbarMenuItem url='/account' name='Account' active={section === 'account' && main} on={section === 'account'} />
        <CustomNavbarMenuItem url='https://support.challengehound.com' name='Help &amp; Support' />
        {hasOrg && <NavbarMenuItem className='mt-4'>
          <Badge content="low" size='sm' color="danger" className='mr-2 ' isInvisible={user.organization.balance > lowBalance}>
            <Button
              radius='sm'
              color='primary'
              variant='bordered'
              as={Link} 
              href='/credits'
              >{formatNumber(user.organization.balance, 1)} Credits</Button>
          </Badge>
        </NavbarMenuItem>}
        <SocialMedia />
      </NavbarMenu>
    </Navbar>
  } else if (section === 'public') {
    return <Navbar className='py-3 min-h-[100px] socks' classNames={{ item: 'px-1' }}>
      <NavbarBrand>
        <Link href='/'>
          <Image src='/challenge-hound-logo.png' alt='Challenge Hound' width={141} height={50} />
        </Link>
      </NavbarBrand>
      <NavbarContent className='justify-center'>
        <NavbarItem isActive={section === 'challenges'}>
          <Link className={linkClass} href='/product'>Product</Link>
        </NavbarItem>
        <NavbarItem isActive={section === 'activities'}>
          <Link className={linkClass} href='/pricing'>Pricing</Link>
        </NavbarItem>
        <NavbarItem isActive={section === 'about'}>
          <Link className={linkClass}  href='/about'>About</Link>
        </NavbarItem>
        <NavbarItem isActive={section === 'houndquarters'}>
          <Link className={linkClass}  href='/houndquarters'>Houndquarters</Link>
        </NavbarItem>
        {!user.id && <NavbarItem isActive={section === 'credits'} className='flex justify-end'>
          <Button
            radius='sm'
            color='primary'
            variant='bordered'
            className='min-w-[100px]'
            as={Link} 
            href='/signin'
            >Sign In</Button>
        </NavbarItem>}
        {user.id && <NavbarItem isActive={section === 'credits'} className='flex justify-end'>
          <Button
            radius='sm'
            color='primary'
            variant='bordered'
            className='min-w-[100px]'
            as={Link} 
            href='/challenges'
            >Dashboard</Button>
        </NavbarItem>}

      </NavbarContent>
      <NavbarMenuToggle
        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        className="sm:hidden"
      />
      <NavbarMenu className='pt-10 w-full'>
        <CustomNavbarMenuItem url='/' name='Home' active={subsection === 'home'} />
        <CustomNavbarMenuItem url='/product' name='Product' active={subsection === 'product'} />
        <CustomNavbarMenuItem url='/pricing' name='Pricing' active={subsection === 'pricing'} />
        <CustomNavbarMenuItem url='/about' name='About' active={subsection === 'about'} />
        <CustomNavbarMenuItem url='/houndquarters' name='Houndquarters' desc=' - join a challenge' active={subsection === 'houndquarters'} />
        {!user.id && <NavbarMenuItem className='mt-4'>
          <Button
            radius='sm'
            color='primary'
            variant='bordered'
            className='min-w-[100px]'
            as={Link} 
            href='/signin'
            >Sign In</Button>
        </NavbarMenuItem>}
        {user.id && <NavbarMenuItem className='mt-4'>
          <Button
            radius='sm'
            color='primary'
            variant='bordered'
            className='min-w-[100px]'
            as={Link} 
            href='/challenges'
            >My Challenges</Button>
        </NavbarMenuItem>}
        <SocialMedia />
      </NavbarMenu>
    </Navbar>
  }
  
}

export default Header
