import React, { useEffect, useState, useContext } from 'react'
import Link from 'next/link'
import { FaSpinner, FaInstagram, FaFacebook } from 'react-icons/fa'
import { FaSquareXTwitter } from 'react-icons/fa6'
import { IoLogoYoutube, IoChatbubbleOutline } from 'react-icons/io5'

import { Button } from '@nextui-org/react'
import localStorage from '../src/local-storage'

import { HelpScout } from '../contexts/help-scout'
import { UserContext } from '../contexts/user-context'

const Footer = () => {
  const helpScout = useContext(HelpScout)
  const userHook = useContext(UserContext)
  const user = userHook.user
  
  const [showFooter, setShowFooter] = useState(false)
  const [showCookieConsent, setShowCookieConsent] = useState(false)
  const [cookieConsent, setCookieConsent] = useState()
  useEffect(()  => {
    const cc = localStorage.getCookieConsent()
    if (typeof cc === 'undefined' && (!user.id || user.cookies === null || user.cookies === 'null')) setShowCookieConsent(true)
  }, [user.id])

  useEffect(() => {
    if (!window.isNativeApp) {
      setTimeout(() => {
        setShowFooter(true)
      }, 500)
    }
  }, [])

  const action = async (consent) => {
    setShowCookieConsent(false)
    if (user.id) {
      try {
        const { ['challengesJoined']: remove1, ['groupsJoined']: remove2, ['organizations']: remove3, ['integrations']: remove4, ...trimmed } = user
        trimmed.cookies = consent
        const putConfig = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': user.token
          },
          body: JSON.stringify(trimmed)
        }
        const res = await fetch(`${process.env.API}/users/${user.id}`, putConfig)
        if (!res.ok) {
          const errMsg = await res.text()
          console.error(errMsg)
        }
      
        userHook.mutate()
      } catch (err) {
        console.error(err)
      }
    } else {
      localStorage.setCookieConsent(consent)
    }
  }
  
  if (!showFooter) return <div style={{ height: '30px' }}></div>

  return <div className='bg-chDarkBlue mt-[75px] text-chOffWhite h-[50px] pt-3 w-full'>
    
    {showCookieConsent && <div className='bg-[#484848] text-white fixed bottom-[10px] md:bottom-[70px] left-[10px] w-[300px] h-[140px] p-2 rounded-md'>
      By using Challenge Hound, you agree to our <Link href='/cookies' className='text-primary'>Cookie Policy</Link>. Some features will not work if you reject cookies.<br/>
      <div className='grid grid-cols-2 mt-3'>
        <Button color='danger' className='mr-2' onPress={() => action(false)}>Reject</Button>
        <Button color='success' onPress={() => action(true)}>Accept</Button>
      </div>
    </div>}
    
    {!helpScout.helpScoutLoaded && <div className='hidden md:block absolute md:fixed bg-chOffWhite shadow-lg rounded-full text-secondary text-[2em] md:p-4 md:bottom-[45px] z-[49] right-[45px] cursor-pointer hover:bg-chOffWhite-200 hover:text-[2.2em]' onClick={() => {
      helpScout.loadBeacon(true)
    }}>{helpScout.helpScoutLoading ? <FaSpinner className='text-primary' /> : <IoChatbubbleOutline />}</div>}
    
    <div className='hidden md:block max-w-[970px] mx-auto'>
      <div className='grid grid-cols-5'>
        <div className='text-chOffWhite grow'>&copy; Challenge Hound LLC</div>
        <div className='flex items-center justify-center'>
          <div className='mr-4'><a href='https://x.com/ChallengeHound'><FaSquareXTwitter className='text-chOffWhite' /></a></div>
          <div className='mr-4'><a href='https://instagram.com/challengehound'><FaInstagram  className='text-chOffWhite' /></a></div>
          <div className='mr-4'><a href='https://facebook.com/challengehound'><FaFacebook  className='text-chOffWhite' /></a></div>
          <div className='text-xl'><a href='https://www.youtube.com/@challengehound4806'><IoLogoYoutube  className='text-chOffWhite' /></a></div>
        </div>
        <div className='text-right'><Link className='text-chOffWhite hover:text-gray-50' href='/privacy'>Privacy</Link></div>
        <div className='text-right'><Link className='text-chOffWhite hover:text-gray-50' href='/cookies'>Cookies</Link></div>
        <div className='text-right'><Link  className='text-chOffWhite hover:text-gray-50' href='/terms'>Terms</Link></div>
      </div>
    </div>

    <div className='md:hidden'>
      <div className='grid grid-cols-3'>
        <div className='text-center'><Link className='text-chOffWhite hover:text-gray-50' href='/privacy'>Privacy</Link></div>
        <div className='text-center'><Link className='text-chOffWhite hover:text-gray-50' href='/cookies'>Cookies</Link></div>
        <div className='text-center'><Link  className='text-chOffWhite hover:text-gray-50' href='/terms'>Terms</Link></div>
      </div>
    </div>
    
  </div>
}

export default Footer
